<template>
  <div class="m-heading">
    <div class="m-heading__line"></div>
    <p data-aos="fade" class="m-heading__text" v-if="!lineOnly">{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: "Heading",
  props: {
    title: {
      type: String,
      default: '',
    },
    lineOnly: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped lang="scss">
.m-heading {
  position: relative;
  display: block;
  
  &__line {
    display: none;
    
    @media #{$lg} {
      position: fixed;
      display: block;
      height: 100%;
      width: 120px;
      left: 0;
      top: 0;
      z-index: 2;
    }
    
    @media #{$xxl} {
      width: 134px;
    }
    
    &:after {
      content: '';
      background-color: $color-line;
      position: absolute;
      height: 100%;
      width: 1px;
      right: 0;
      top: 0;
    }
  }
  
  // @media #{$lg} {
  //   position: fixed;
  //   left: 0;
  //   top: 0;
  //   width: 120px;
  //   height: 100%;
  //   border-right: 1px solid $color-line;

  //   @media #{$xxl} {
  //     width: 134px;
  //   }
  // }

  &__text {
    @extend %summary-title;
    color: $color-text;
    margin: 0 0 10px 0;
  }
}
</style>
