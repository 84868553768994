<template>
  <a href="#" class="e-button" :class="classes" @click.prevent="handleClick()">
    <span v-if="!loading">{{ title }}</span>
    <loading-progress
      :class="{ active: loading }"
      :indeterminate="true"
      :hide-background="true"
      size="50"
      rotate
      fillDuration="2"
      rotationDuration="1"
    />
  </a>
  <!-- / e-button -->

</template>

<script>
export default {
  name: "Button",
  props: {
    title: {
      type: String
    },
    classes: {
      type: String
    },
    path: {
      type: String
    },
    url: {
      type: String
    },
    event: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  methods: {
    handleClick() {
      if (this.path) {
        this.$router.push({ path: this.path }).catch(() => {});
      } else if (this.url) {
        window.location.href = this.url;
      } else {
        this.$emit('clicked');
      }
    }
  }
};
</script>

<style scoped lang="scss">
  .e-button {
    @include transition();
    @extend %product-name;
    position: relative;
    display: inline-block;
    min-width: 100px;
    height: 50px;
    text-align: center;
    font-weight: 600;
    line-height: 46px;
    cursor: pointer;
    border: 2px solid $color-text;
    
    @media (min-width: 360px) {
      min-width: 120px;
    }

    &--disabled {
      color: $color-text;
      cursor: not-allowed;
    }

    &--primary {
      border-color: $color-primary;
      color: $color-white;
      background-color: $color-primary;

      &:hover {
        background-color: transparent;
        color: $color-primary;
      }
    }

    &--secondary {
      border-color: $color-primary;
      color: $color-primary;
      background-color: transparent;

      &:hover {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }

</style>
